
import {
  defineComponent,
  reactive,
  SetupContext,
  toRefs,
  onBeforeMount,
} from 'vue';
import resource from '@/modules/resource';
import investmentModule from '@/modules/investment/investmentModule';
import validation from '@/modules/validation';
import { AxiosResponse, AxiosError } from 'axios';
import { addBankDetails } from '@/modules/requests';

export default defineComponent({
  inheritAttrs: false,
  emits: ['back', 'navigateToConfirmWithdraw'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData, mapFormErrors } = resource();
    const { bankOptions, allBanks } = investmentModule();
    const { validateForm } = validation();

    const data = reactive({
      form: basicForm([
        {
          name: 'account_number',
          rules: 'required|number',
        },
        {
          name: 'bank_name',
          rules: 'required',
        },
        {
          name: 'account_name',
          rules: 'required',
        },
      ]),
    });

    onBeforeMount(allBanks);

    const addAccount = async (): Promise<any> => {
      if (!validateForm(data.form)) return false;
      await addBankDetails(getFormData(data.form))
        .then((response: AxiosResponse) => {
          emit('navigateToConfirmWithdraw', response?.data);
        })
        .catch((error: AxiosError) => {
          (data.form.error = error?.response?.data?.message),
            mapFormErrors(data.form, error?.response?.data?.errors);
        })
        .finally(() => (data.form.loading = false));
    };

    return { ...toRefs(data), addAccount, bankOptions };
  },
});
