<template>
  <div
    class="inline-flex items-center mb-3 cursor-pointer"
    @click="$emit('back')"
  >
    <icon-back />
    <span class="ml-2 text-xs text-invest-purple">Back</span>
  </div>
  <div class="text-2xl font-bold text-invest-primary">Withdraw Investment</div>
  <p class="mt-2 mb-10 text-xs text-invest-dark">
    Enter your bank account information to continue.
  </p>
  <form @submit.prevent="addAccount">
    <form-input
      placeholder="Account number"
      name="account_number"
      :form="form"
      v-model="form.data.account_number.value"
    />
    <form-input
      type="select"
      :options="bankOptions"
      defaultValue="Select Bank"
      name="bank_name"
      :form="form"
      v-model="form.data.bank_name.value"
    />
    <form-input
      placeholder="Account name"
      name="account_name"
      :form="form"
      v-model="form.data.account_name.value"
    />

    <div class="mt-26">
      <button type="submit" class="btn btn-primary" :disabled="form.loading">
        Add Account
      </button>
    </div>
  </form>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  SetupContext,
  toRefs,
  onBeforeMount,
} from 'vue';
import resource from '@/modules/resource';
import investmentModule from '@/modules/investment/investmentModule';
import validation from '@/modules/validation';
import { AxiosResponse, AxiosError } from 'axios';
import { addBankDetails } from '@/modules/requests';

export default defineComponent({
  inheritAttrs: false,
  emits: ['back', 'navigateToConfirmWithdraw'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData, mapFormErrors } = resource();
    const { bankOptions, allBanks } = investmentModule();
    const { validateForm } = validation();

    const data = reactive({
      form: basicForm([
        {
          name: 'account_number',
          rules: 'required|number',
        },
        {
          name: 'bank_name',
          rules: 'required',
        },
        {
          name: 'account_name',
          rules: 'required',
        },
      ]),
    });

    onBeforeMount(allBanks);

    const addAccount = async (): Promise<any> => {
      if (!validateForm(data.form)) return false;
      await addBankDetails(getFormData(data.form))
        .then((response: AxiosResponse) => {
          emit('navigateToConfirmWithdraw', response?.data);
        })
        .catch((error: AxiosError) => {
          (data.form.error = error?.response?.data?.message),
            mapFormErrors(data.form, error?.response?.data?.errors);
        })
        .finally(() => (data.form.loading = false));
    };

    return { ...toRefs(data), addAccount, bankOptions };
  },
});
</script>
